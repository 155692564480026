import './components/jquery.responsifyBgImg';
import './components/checkflexgap';
import './components/isInViewport';
import {runAnimations} from './components/countup';
import './vendor/slick.min';
import './vendor/lity.min';

(function($) {

    const $body = $('body');
    let submenuDisplay;
    let winW  = $(window).width();

    let animationRun = false;

	  // Offcanvas

    $('.js-toggle-nav').on('click', () => {
      $body.toggleClass('menu-open');
    });

    // Offcanvas Submenu
    if (winW < 1024) {

        $('body').on('click', '.js-submenu', function(e) {

            e.preventDefault();
            e.stopPropagation();
            let $this = $(this);
            let action = $this.data('action');
            let level = $this.data('level');
            let current = $this.data('current');

            if (submenuDisplay == true && action == 'close') {
                hideSubMenu(level);
            }

            if (action == 'open') {
                displaySubMenu($this, level);
            }
        });

        function displaySubMenu(el, lvl) {
            $body.addClass('submenu-open');
            el.next('.sub-menu').toggleClass('sub-menu--active');
            //$('#submenu-close').data('current', lvl);
           
            submenuDisplay = true;
        }

        function hideSubMenu(lvl) {
            if (lvl == 0) {
                $body.removeClass('submenu-open');
                submenuDisplay = false;
            } 

            $('.sub-menu.level-' + lvl).removeClass('sub-menu--active');
            //$('#submenu-close').data('current', lvl - 1);
            console.log($('#submenu-close').data('current'));
        }
    }

    // Sticky Header

    const observer = new IntersectionObserver( 
        ([e]) => e.target.toggleAttribute('stuck', e.intersectionRatio < 1),
        {threshold: [1]}
    );

    observer.observe(document.getElementById('site-header'));

    // Slick Slider

    if($('.slick-slider--offset-carousel').length>0) {

        $.each( $('.slick-slider--offset-carousel'), function( key, value ) {

            var slideCount = $(this).children().length;

            if (slideCount <= 3) {
                // clone element
                $(this).children().clone(true, true).appendTo($(this));
            }
        });
    }

    let slideCount768, slideCount1200, slideCount1400, slideCount1600;

    if($('.slick-slider--offset-carousel').hasClass('type3')) {
        slideCount768 = 3;
        slideCount1200 = 4;
        slideCount1400 = 4;
        slideCount1600 = 5;
    } else {
        slideCount768 = 2;
        slideCount1200 = 3;
        slideCount1400 = 3;
        slideCount1600 = 4;
    }

    $('.slick-slider--offset-carousel').slick({
 
        dots: false,
        arrows: false,
        infinite: true,
        speed: 300,
        prevArrow: '.offset-carousel__prev',
        nextArrow: '.offset-carousel__next',
        mobileFirst: true,
        responsive: [
            {
                breakpoint: 1600,
                settings: {
                    slidesToShow: slideCount1600,
                    slidesToScroll: 1,
                    infinite: true,
                }
            },
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: slideCount1400,
                    slidesToScroll: 1,
                    infinite: true,
                }
              },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: slideCount1200,
                    slidesToScroll: 1,
                    infinite: true,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: slideCount768,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
          // You can unslick at a given breakpoint now by adding:
          // settings: "unslick"
          // instead of a settings object
        ]
       
    });

    $('.offset-carousel__prev').on('click', function(){
        $('.slick-slider--offset-carousel').slick('slickPrev');
      });
      $('.offset-carousel__next').on('click', function(){
        $('.slick-slider--offset-carousel').slick('slickNext');
      });

    // Stage Carousel
    $('.slick-slider--stage-carousel').slick({
 
        dots: false,
        arrows: false,
        infinite: true,
        speed: 300,
        prevArrow: '.stage-carousel__prev',
        nextArrow: '.stage-carousel__next',
        mobileFirst: true,
        responsive: [
            {
                breakpoint: 1600,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    infinite: true,
                }
            },

            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
          // You can unslick at a given breakpoint now by adding:
          // settings: "unslick"
          // instead of a settings object
        ]
       
    });

    $('.stage-carousel__prev').on('click', function(){
        $('.slick-slider--stage-carousel').slick('slickPrev');
    });
    
    $('.stage-carousel__next').on('click', function(){
        $('.slick-slider--stage-carousel').slick('slickNext');
    });

    $('.stage-carousel__bar-nav__button').on('click', function(){
        var stage = $(this).data('stage');
        var slideIndex;

        $.each( $('.stage-carousel__item'), function( key, value ) {

            if($(this).data('stage') == stage) {
                slideIndex = $(this).data('slick-index');

                if (slideIndex > -1) {
                    return false;
                }
            }

        });

        $('.slick-slider--stage-carousel').slick( 'slickGoTo', slideIndex );
    });

    // On after slide change
    // $('.slick-slider--stage-carousel').on('afterChange', function(event, slick, currentSlide, nextSlide){
    //     var stageClass = $('.stage-carousel__item.slick-current').data('stage');

    //     $('.stage-carousel__bar-nav').attr('id', stageClass);
    // });


    $('.slick-slider--stage-carousel').on('beforeChange', function(event, slick, currentSlide, nextSlide){
        console.log(nextSlide);
        var $nextSlide = $('.stage-carousel__item[data-slick-index="'+nextSlide+'"]');
        var stageClass = $nextSlide.data('stage');

        $('.stage-carousel__bar-nav').attr('id', stageClass);
    });


    // $('.slick-slider--partner-carousels--top').slick({
    //     speed: 5000,
    //     autoplay: true,
    //     autoplaySpeed: 0,
    //     cssEase: 'linear',
    //     slidesToShow: 1,
    //     slidesToScroll: 1,
    //     variableWidth: true,
    //     dots: false,
    //     arrows: false,
    //     rtl:true
    //   });  

    // $('.slick-slider--partner-carousels--bottom').slick({
    //     speed: 5000,
    //     autoplay: true,
    //     autoplaySpeed: 0,
    //     cssEase: 'linear',
    //     slidesToShow: 1,
    //     slidesToScroll: 1,
    //     variableWidth: true,
    //     dots: false,
    //     arrows: false
        
    // });

    $('.slick-slider--partner-carousel').slick({
        speed: 7000,
        autoplay: true,
        autoplaySpeed: 0,
        cssEase: 'linear',
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: true,
        dots: false,
        arrows: false
        
    });
      

    // Partners Carousel Hovers


    $( '.partner-carousel__link' ).on( "mouseenter", function() {
        var targets = $(this).data('targets');
        $(targets).addClass('partner-carousel__item--active');
    } );

    $( '.partner-carousel__link' ).on( "mouseleave", function() {
        $('.partner-carousel__item--active').removeClass('partner-carousel__item--active');
    } );

    // Slick Slider

    $('.slick-slider--testimonial-carousel').slick({
 
        dots: false,
        arrows: true,
        infinite: true,
        speed: 300,
        prevArrow: '.testimonial-carousel__prev',
        nextArrow: '.testimonial-carousel__next'
       
    });

    // Scroll to ID

    $('.js-scrollto').on('click', function () {
        var id = $(this).data('target');
        scrollTo(id);
    });

    function scrollTo(id){
        var el;
        var scrollPos;
        var winW = $(window).width();

        if (id === 'top') {
            scrollPos = 0;
        } else {
            el = $(id);
            scrollPos = el.offset().top-150;
        }

        $('html,body').animate({scrollTop: scrollPos},'slow');
    }

    // Responsive Background Images

    $('.js-responsive-bg').responsifybgimg();

	// Footcols

    $('.site-footer__column__heading').on('click', function () {
        $(this).parent('.site-footer__column').toggleClass('open');
    });

    // Animate on Scroll

    $('.js-visible:in-viewport(0)').addClass('is-visible');


    $(window).on('scroll', function() {
        $('.js-visible:in-viewport(-250)').addClass('is-visible');

        if ($('.stat-counter').hasClass('is-visible') && animationRun === false) {
            runAnimations();
            animationRun = true;
        }
    });

    // Dropdown List

    $('.js-dropdown-list').on('click', function() {
        var $this = $(this);
        var index = $this.data('index');
        $('.js-dropdown-list').not($this).parent().removeClass('dropdown-list__item--is-open');
        $this.parent().toggleClass('dropdown-list__item--is-open');

        $('.two-column__list-image').removeClass('active');
        $('#list-image'+index).addClass('active');
    });

    // Tabs

    $('.js-tab').on('click', function() {
        var $this = $(this);
        if (!$this.hasClass('tabbed-panels__tab--active')) {
            $('.tabbed-panels__tab--active').removeClass('tabbed-panels__tab--active');
            $('.tabbed-panels__item--active').removeClass('tabbed-panels__item--active is-visible');
            var target = $this.data('target');
            var tab_index = $this.data('tab-index');

            if(!tab_index && tab_index != 0) {
                $this.addClass('tabbed-panels__tab--active');
            } else {
                var i = tab_index;
                $('.tabbed-panels__tab').eq(i).addClass('tabbed-panels__tab--active');
            }
            
            changePanel(target);
        }
    });

    function changePanel(panel) {
        
        $(panel).addClass('tabbed-panels__item--active');
        
        setTimeout(function() {
            $(panel).addClass('is-visible');
        },200);
    }


})(jQuery);